import React from "react"
import { DefaultLayout } from "layouts"
import { NewsInterior } from "containers"
import { Article } from "components"

import bgHeader from "../images/bg-header.png"
import articleThumbnail01 from "../images/article-thumbnail-01.png"
import articleThumbnail02 from "../images/article-thumbnail-02.png"
import articleThumbnail03 from "../images/article-thumbnail-03.png"
import { graphql } from "gatsby"
import { get } from "lodash"
import { getImage } from "gatsby-plugin-image"

const Index = (props: any) => {
  const {
    data: {
      markdownRemark: {
        frontmatter: news,
        fields: { slug },
      },
    },
  } = props

  const articleProps: IArticle = {
    thumbnail: getImage(get(news, "thumbnail")),
    date: get(news, "date", ""),
    title: get(news, "title", ""),
    description: get(news, "description", ""),
    slug,
    content: get(news, "content", ""),
  }

  const boxWrapperProps = {
    heroProps: {
      background: bgHeader,
      headline: "B&F News",
    },
    boxContent: <Article {...articleProps} />,
    breadcrumbProps: {
      paths: [
        {
          path: "/",
          label: "Home",
        },
        {
          path: "/news",
          label: "B&F News",
        },
        {
          path: `/news/${slug}`,
          label: get(news, "title", ""),
        },
      ],
    },
  }

  const relatedArticlesProps: IRelatedArticles = {
    headline: "Related news",
    articles: [
      {
        thumbnail: articleThumbnail01,
        date: "September 01 2021",
        title: "I don’t care if a reader hates one of my stories",
        description:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
        slug: "/",
      },
      {
        thumbnail: articleThumbnail02,
        date: "September 01 2021",
        title: "I don’t care if a reader hates one of my stories",
        description:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
        slug: "/",
      },
      {
        thumbnail: articleThumbnail03,
        date: "September 01 2021",
        title: "I don’t care if a reader hates one of my stories",
        description:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
        slug: "/",
        hasVideo: true,
      },
    ],
    actions: [
      {
        text: "View  More",
        url: "/",
      },
    ],
  }

  const newsProps: INewsInteriorContainerProps = {
    boxWrapperProps,
    relatedArticlesProps,
  }

  return (
    <DefaultLayout {...props}>
      <NewsInterior {...newsProps} />
    </DefaultLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      fileAbsolutePath: {regex: "/(news)/.*\\.md$/"}
    ) {
      fields {
        slug
      }
      frontmatter {
        author
        content
        date(formatString: "MMMM DD YYYY")
        description
        title
        thumbnail {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
  }
`

export default Index
